import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import useAppDispatch from '../../hooks/useAppDispatch'

export interface ProfileState {
  IbanChangeRequested: boolean
  activeServices: string[]
}

const initialState: ProfileState = {
  IbanChangeRequested: false,
  activeServices: [],
}

const { reducer, actions } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIbanRequestedState: (state, action: PayloadAction<ProfileState['IbanChangeRequested']>) => {
      state.IbanChangeRequested = action.payload
    },
    setActiveServices: (state, actions: PayloadAction<ProfileState['activeServices']>) => {
      state.activeServices = actions.payload
    },
  },
})

export const useIbanRequestedAction = () => {
  const dispatch = useAppDispatch()
  return (payload: ProfileState['IbanChangeRequested']) => {
    dispatch(actions.setIbanRequestedState(payload))
  }
}

export const useActiveServicesActions = () => {
  const dispatch = useAppDispatch()
  return (payload: ProfileState['activeServices']) => {
    dispatch(actions.setActiveServices(payload))
  }
}

export const { setIbanRequestedState, setActiveServices } = actions
export default reducer

import notificationIcon from '../../assets/svg/notification.svg'
import karnamehLogo from '../../assets/png/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { routePaths, routeTitles } from '../../routes/routes'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, Text, Image } from '@chakra-ui/react'

const Appbar = () => {
  const { pathname } = useLocation()
  const navigator = useNavigate()
  const location = useLocation()
  const routePathKey =
    (Object.entries(routePaths).find(([_, itemValue]) => {
      return itemValue === pathname
    })?.[0] as keyof typeof routeTitles) ?? 'HOME'

  if (['', '/', routePaths.PROFILE, routePaths.SERVICES].includes(pathname)) return <></>
  return (
    <Flex
      flexDir='row'
      align='center'
      px='20px'
      py='18px'
      justify='space-between'
      boxSize='full'
      position='sticky'
      width='full'
      bg='white.primary'
      zIndex='1'
      boxShadow='0px 0px 8px rgba(0, 0, 0, 0.05)'
      top='0'
      height='64px'
      gap='10px'
    >
      {[
        routePaths.NOTIFICATIONS,
        routePaths.FINANCE_REPORT,
        routePaths.SETTINGS,
        routePaths.EXPERT_PERFORMANCE,
      ].includes(pathname) ? (
        <Flex w='full' gap='8px' justify='start' alignItems='center'>
          <ArrowForwardIcon
            w='24px'
            onClick={() => {
              if (location.key === 'default') {
                navigator(`/`)
              } else {
                navigator(-1)
              }
            }}
          />

          <Text textStyle='appBarTitle'>{routeTitles[routePathKey]}</Text>
        </Flex>
      ) : pathname === routePaths.AUTH ? (
        <Text textStyle='appBarTitle'>ورود به حساب کاربری</Text>
      ) : (
        <>
          <Image alt='karnameh-logo' src={karnamehLogo} />
          <Image alt='notification-icon' src={notificationIcon} />
        </>
      )}
    </Flex>
  )
}
export default Appbar

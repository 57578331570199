import jwtDecode from 'jwt-decode'
import { JWTDataDTO } from '../api/user/User.dto'
import { routePaths } from '../routes/routes'
import { ACCESS_TOKEN } from './consts'
import { getAccessTokenFromCookie, removeCookie, setAccessTokenCookie } from './cookieManager'
import { getAccessTokenFromLocalStorage, setAccessTokenToLocalStorage } from './localStorageManager'

export const userIsLoggedIn = () => {
  if (!getAccessTokenFromCookie() && getAccessTokenFromLocalStorage() === '') {
    return false
  }
  if (!getAccessTokenFromCookie() && getAccessTokenFromLocalStorage() !== '') {
    setAccessTokenCookie(getAccessTokenFromLocalStorage())
  }
  return true
}

export function getPersistentUserToken(): string | undefined {
  return getAccessTokenFromCookie() ?? undefined
}

export const getUserDataFromToken = (token: string | undefined): JWTDataDTO | void => {
  try {
    if (token) return jwtDecode<JWTDataDTO>(token)
    return jwtDecode<JWTDataDTO>(getAccessTokenFromCookie())
  } catch (e) {
    removeCookie(ACCESS_TOKEN)
    setAccessTokenToLocalStorage('')
    window.location.replace(routePaths.AUTH)
  }
}

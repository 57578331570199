import { ComponentStyleConfig, extendTheme, ThemeOverride } from '@chakra-ui/react'
import { colors } from './colors'

const activeLabelStyles = {
  // transform: 'scale(0.85) translateY(-24px)',
}

const inputStyles: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        height: '64px',
        padding: '15px',
        border: '1px solid rgba(0, 0, 0, 0.38)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        layerStyle: 'input-text',
        fontSize: '16px',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'accent.primary',
    errorBorderColor: 'error.primary',
  },
}

const layerStyles = {
  inputText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontsize: '16px',
    lineHeight: '32px',
    color: 'grey.800',
  },
}

const textStyles = {
  appBarTitle: {
    fontWeight: 'semibold',
    fontSize: '18px',
    lineHeight: '36px',
    color: 'grey.800',
  },
  pageTitle: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'grey.800',
  },
  body: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'grey.800',
  },
  captionLarge: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'grey.800',
  },
  captionSmall: {
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '20px',
    color: 'grey.800',
  },
  formLabel: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'grey.800',
  },
  buttonLabel: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'white',
  },

  //Title Typographies
  titleLarge: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2.5rem',
  },
  titleMedium: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.875rem',
  },
  titleSmall: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '2.25rem',
  },
  titleXSmall: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
  //Body Typographies
  bodyLarge: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
  },
  bodyMedium: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  bodySmallSemi: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  bodySmallRegular: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.75rem',
  },
  //Caption Typographies
  captionLargeSemi: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
  captionLargeRegular: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  captionMediumSemi: {
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  captionMediumRegular: {
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  //Card Typographies
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
  cardSubTitle: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  cardCaption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
}

const buttonStyle: ComponentStyleConfig = {
  variants: {
    solid: {
      bg: 'brand.primary',
      color: 'white',
      height: '40px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '28px',
      outline: 'none',
      boxShadow: 'none',
      boxSizing: 'border-box!',
      _disabled: {
        bg: 'black.light-100',
      },
    },
    outline: {
      borderColor: 'brand.primary',
      color: 'brand.primary',
      boxSizing: 'border-box!',
    },
  },
}
export const theme: ThemeOverride = extendTheme({
  fonts: {
    body: `'yekanbakh', sans-serif`,
  },
  colors: colors,
  layerStyles: layerStyles,
  textStyles: textStyles,
  components: {
    Input: inputStyles,
    Button: buttonStyle,
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '24px',
              mx: 2,
              px: 2,
              mt: '-10px',
              transformOrigin: 'left top',
            },
          },
        },
        'floating-icon': {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '24px',
              mx: 2,
              px: 2,
              mb: 2,
              mt: '-10px',
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
    Alert: {
      variants: {
        apiError: {
          container: {
            bg: 'white',
            m: '10px',
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px',
            border: '0.5px solid #E6E6E6',
          },
          title: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            color: 'grey.800',
          },
          description: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            color: 'grey.800',
          },
          icon: {
            color: 'error.primary',
          },
        },
      },
    },
  },
})

import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react'
import useAppSelector from '../../hooks/useAppSelector'
import { useNoticeBsAction } from '../../store/slices/appSlice'

const NoticeBS = () => {
  const { noticeBottomSheet } = useAppSelector((state) => state.app)
  const toggleBs = useNoticeBsAction()

  const toggleBsState = (state: boolean) => {
    toggleBs({
      open: state,
      title: state ? noticeBottomSheet.title : '',
      body: state ? noticeBottomSheet.body : '',
      hasButton: true,
    })
  }

  return (
    <Drawer placement='bottom' onClose={() => toggleBsState(false)} isOpen={noticeBottomSheet.open}>
      <DrawerOverlay />
      <DrawerContent
        bg='white'
        py='24px'
        borderTopRadius='8px'
        boxShadow='0px 2px 2px rgba(97, 100, 117, 0.06), 0px 3px 1px rgba(97, 100, 117, 0.04), 0px 1px 5px rgba(97, 100, 117, 0.12)'
      >
        <DrawerHeader display='flex' flexDirection='row' justifyContent='space-between'>
          <Text textStyle='pageTitle'>{noticeBottomSheet.title}</Text>
          <CloseButton onClick={() => toggleBsState(false)} autoFocus={true} bg='transparent' />
        </DrawerHeader>
        <DrawerBody px='16px' py='32px'>
          <Text textStyle='body' textAlign='justify'>
            {noticeBottomSheet.body}
          </Text>
        </DrawerBody>
        {noticeBottomSheet.hasButton && (
          <DrawerFooter>
            <Button
              _focus={{
                bg: 'transparent',
              }}
              onClick={() => toggleBsState(false)}
              borderColor='brand.primary'
              variant='outline'
              w='full'
            >
              <Text textStyle='pageTitle' color='brand.primary'>
                متوجه شدم
              </Text>
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
export default NoticeBS

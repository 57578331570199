import { Box } from '@chakra-ui/react'
import Appbar from './components/common/Appbar'
import NoticeBS from './components/common/NoticeBS'
import {Suspense, useEffect, useRef} from 'react'
import { useRoutes } from 'react-router-dom'
import { routes } from './routes/routes'
import FallbackLoading from './pages/FallbackLoading'
import ProgressIndicatorModal from './components/common/ProgressIndicatorModal'

function App() {
  const content = useRoutes(routes)

  return (
    <Box width='100%' height='100%'>
      <Suspense fallback={<FallbackLoading />}>
        <Appbar />
        <Box width='100%' height='calc(100vh - 64px)'>
          {content}
        </Box>
        <NoticeBS />
        <ProgressIndicatorModal />
      </Suspense>
    </Box>
  )
}

export default App

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import useAppDispatch from '../../hooks/useAppDispatch'

export interface AppState {
  backDropLoading: boolean
  toast: { state: boolean; message: string }
  appBar: {
    title: string
  }
  user?: {
    username: string
    id: string
    token: string
  }
  noticeBottomSheet: {
    open: boolean
    title: string
    body: string
    hasButton?: boolean
  }
  fcmToken?: string
  progressIndicator: {
    progress?: number
  }
}

const initialState: AppState = {
  backDropLoading: false,
  toast: { state: false, message: '' },
  appBar: {
    title: '',
  },
  noticeBottomSheet: {
    open: false,
    title: '',
    body: '',
  },
  progressIndicator: {
    progress: undefined,
  },
}

const { reducer, actions } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setBackDropState: (state, action: PayloadAction<AppState['backDropLoading']>) => {
      state.backDropLoading = action.payload
    },
    setToastState: (state, action: PayloadAction<AppState['toast']>) => {
      state.toast = action.payload
    },
    setUser: (state, action: PayloadAction<AppState['user']>) => {
      state.user = action.payload
    },
    setAppBar: (state, action: PayloadAction<AppState['appBar']>) => {
      state.appBar = action.payload
    },
    setNoticeBottomSheet: (state, action: PayloadAction<AppState['noticeBottomSheet']>) => {
      state.noticeBottomSheet = action.payload
    },
    setFcmToken: (state, action: PayloadAction<AppState['fcmToken']>) => {
      state.fcmToken = action.payload
    },
    setProgressIndicator: (state, action: PayloadAction<AppState['progressIndicator']>) => {
      state.progressIndicator = action.payload
    },
  },
})

export const useBackDropAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['backDropLoading']) => {
    dispatch(actions.setBackDropState(payload))
  }
}

export const useToastAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['toast']) => {
    dispatch(actions.setToastState(payload))
  }
}

export const useUserAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['user']) => {
    dispatch(actions.setUser(payload))
  }
}

export const useAppBarAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['appBar']) => {
    dispatch(actions.setAppBar(payload))
  }
}

export const useNoticeBsAction = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['noticeBottomSheet']) => {
    dispatch(actions.setNoticeBottomSheet(payload))
  }
}

export const useProgressIndicatorActions = () => {
  const dispatch = useAppDispatch()
  return (payload: AppState['progressIndicator']) => {
    dispatch(actions.setProgressIndicator(payload))
  }
}
export const {
  setUser,
  setBackDropState,
  setToastState,
  setAppBar,
  setFcmToken,
  setNoticeBottomSheet,
  setProgressIndicator,
} = actions
export default reducer

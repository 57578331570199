import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import AuthGuard from '../pages/AuthGuard'
const Home = lazy(() => import('../pages/Home'))
const Auth = lazy(() => import('../pages/Auth'))
const Profile = lazy(() => import('../pages/Profile'))
const Notifications = lazy(() => import('../pages/Notifications'))
const Service = lazy(() => import('../pages/Service'))
const Settings = lazy(() => import('../pages/SettingsPage'))
const FinanceReport = lazy(() => import('../pages/FinanceReport'))
const ExpertPerformance = lazy(() => import('../pages/ExpertPerformance'))

export const routePaths = {
  HOME: '',
  AUTH: '/auth',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications-list',
  SERVICES: '/service',
  SETTINGS: '/settings',
  FINANCE_REPORT: '/finance-report',
  EXPERT_PERFORMANCE: '/expert-performance',
}

export const routeTitles = {
  HOME: 'خانه',
  AUTH: 'ورود به حساب کاربری',
  PROFILE: 'تنظیمات حساب کاربری',
  NOTIFICATIONS: 'پیام‌ها',
  SERVICES: 'خدمات',
  FINANCE_REPORT: 'گزارش مالی',
  NOTIFICATION: 'پیام‌ها',
  SETTINGS: 'حساب کاربری',
  EXPERT_PERFORMANCE: 'گزارش عملکرد',
}

export const routes: RouteObject[] = [
  {
    path: routePaths.AUTH,
    element: <Auth />,
  },
  {
    path: routePaths.HOME,
    element: (
      <AuthGuard>
        <Home />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.PROFILE,
    element: (
      <AuthGuard>
        <Profile />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.NOTIFICATIONS,
    element: (
      <AuthGuard>
        <Notifications />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.SERVICES,
    element: (
      <AuthGuard>
        <Service />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.SETTINGS,
    element: (
      <AuthGuard>
        <Settings />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.FINANCE_REPORT,
    element: (
      <AuthGuard>
        <FinanceReport />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.EXPERT_PERFORMANCE,
    element: (
      <AuthGuard>
        <ExpertPerformance />
      </AuthGuard>
    ),
  },
]

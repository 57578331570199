export const ACCESS_TOKEN = 'kpapp-access-token'
export const FCM_TOKEN = 'fcmToken'
export const DEVICE_ID = 'deviceId'

// post message states
export const NOTIFICATIONS_STATE = 'NOTIFICATIONS'
export const BACK_STATE = 'BACK'
export const HOME_STATE = 'HOME'
export const RESET_COOKIE_STATE = 'RESET-COOKIE'
export const REFRESH_DONE_STATE = 'REFRESH-DONE'

export const colors = {
  brand: {
    primary: '#5EBC5E',
    secondary: '#5EBC5E8F',
    hint: '#5EBC5E52',
    divider: '#5EBC5E1F',
    background: '#5EBC5E0A',
    hover: '#82C082',
    press: '#45BF45',
    'accent-primary': '#FFDD00',
    'medium-200': '#D4EAD5',
    'medium-100': '#5EBC5E24',
    'light-200': '#5EBC5E14',
    'light-100': 'brand-light-100',
  },
  black: {
    main: '#000000',
    primary: '#000000DE',
    secondary: '#0000008F',
    deactive: '#A9A9AA',
    divider: '#0000001F',
    border: '#00000014',
    hover: '#1F1F1F8A',
    press: '#00000099',
    tooltip: '#292929F5',
    'dark-100': '#0000007a',
    'medium-200': '#0000003D',
    'medium-100': '#00000029',
    'light-100': '#EFEFF0',
  },
  white: {
    primary: '#FFFFFF',
    secondary: '#ffffffb3',
    nav: '#FFFFFFFA',
    hint: '#ffffff80',
    divider: '#FAFAFB',
  },
  grey: {
    900: '#212121',
    850: '#303030',
    800: '#424242',
    400: '#BDBDBD',
    200: '#EEEEEE',
    100: '#F5F5F5',
    50: '#FAFAFA',
  },
  transparent: '#FFFFFF00',
  success: {
    primary: '#2E7D32',
    secondary: '#7CB342',
  },
  green: {
    A3: '#A3CC72',
    67: '#67AD57',
    '7C': '#7FC36F',
  },
  warning: {
    primary: '#e65100',
    secondary: '#FBC02D',
    hint: '#E651001F',
    divider: '#FBC02D1f',
  },
  error: {
    primary: '#FF0000',
  },
  message: {
    primary: '#F0FAFF',
    hint: '#F0FAFF52',
  },
  accent: {
    primary: '#806CD9',
    secondary: '#F2F0FB',
  },
}

import { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { CheckOtpResponseDTO } from '../../api/auth/Auth.dto'
import { REFRESH_TOKEN } from '../../api/endpoints'
import { routePaths } from '../../routes/routes'
import { providerAxios } from '../../utils/base.api'
import { ACCESS_TOKEN } from '../../utils/consts'
import { removeCookie, setAccessTokenCookie } from '../../utils/cookieManager'
import { setAccessTokenToLocalStorage } from '../../utils/localStorageManager'

const InterceptorComponent = () => {
  const navigator = useNavigate()

  const onResponseFulfilled:
    | ((
        value: AxiosResponse<any, any>,
      ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
    | null
    | undefined = (response) => response

  providerAxios.interceptors.response.use(onResponseFulfilled, async (error) => {
    const { config, response } = error
    switch (config.url) {
      case REFRESH_TOKEN:
        if (response?.status !== 200) {
          removeCookie(ACCESS_TOKEN)
          setAccessTokenToLocalStorage('')
          navigator(routePaths.AUTH, { replace: true })
        }
        break
      default:
        if (response?.status === 401) {
          try {
            const response = await providerAxios.post<CheckOtpResponseDTO>(REFRESH_TOKEN)
            if (response.status === 200) {
              setAccessTokenCookie(response?.data?.access)
              setAccessTokenToLocalStorage(response?.data?.access)
              return providerAxios(config)
            }
          } catch (err) {
            console.log(err)
            return err
          }
        } else {
          return error
        }
        break
    }
  })

  return <></>
}
export default InterceptorComponent

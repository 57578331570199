import Cookies from 'universal-cookie'
import { ACCESS_TOKEN, DEVICE_ID, FCM_TOKEN } from './consts'
import { getUserDataFromToken } from './userAuthTool'

const cookies = new Cookies()

export const getFcmTokenFromCookie = () => {
  return cookies.get(FCM_TOKEN)
}

export const setFcmTokenCookie = (fcmToken: string) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)
  cookies.set(FCM_TOKEN, fcmToken, { expires })
}

export const getAccessTokenFromCookie = () => {
  return cookies.get(ACCESS_TOKEN)
}

export const setAccessTokenCookie = (accessToken: string) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)
  cookies.set(ACCESS_TOKEN, accessToken, { expires })
}

export const getDeviceIdFromCookie = () => {
  return cookies.get(DEVICE_ID)
}

export const setDeviceIdCookie = (deviceId: string) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)
  cookies.set(DEVICE_ID, deviceId, { expires })
}

export const getCookieByKey = (key: string) => {
  return cookies.get(key)
}

export const setCookieByKey = (key: string, value: string) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)
  cookies.set(key, value, { expires })
}

export const removeCookie = (key: string) => {
  cookies.remove(key)
}

export const getUserFromCookie = () => {
  const token = getCookieByKey(ACCESS_TOKEN)
  return getUserDataFromToken(token)
}

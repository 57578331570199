import { CircularProgress, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import useAppSelector from '../../hooks/useAppSelector'
import { useProgressIndicatorActions } from '../../store/slices/appSlice'

const ProgressIndicatorModal = () => {
  const { progressIndicator } = useAppSelector((state) => state.app)
  const progressIndicatorAction = useProgressIndicatorActions()
  return (
    <Modal
      isCentered={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={progressIndicator.progress !== undefined}
      onClose={() => {
        progressIndicatorAction({ progress: undefined })
      }}
    >
      <ModalOverlay />
      <ModalContent mx='16px'>
        <ModalBody margin='auto' py='16px'>
          <CircularProgress
            value={progressIndicator.progress ? progressIndicator.progress : 0}
            color='brand.primary'
            padding='auto'
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ProgressIndicatorModal

// SWITCH endpoints
export const SEND_OTP = 'switch/auth/otp/send/'
export const CHECK_OTP = 'switch/auth/otp/confirm/'
export const REFRESH_TOKEN = 'switch/auth/refresh/'
export const SERVICES_LIST = 'switch/services/'
export const UPDATE_CURRENT_USER = 'switch/users/current-user/'
export const UPLOAD_MEDIA = 'switch/media/'

// BOOGH endpoints
export const CURRENT_USER = 'switch/users/current-user/'
export const PUSH_TOKEN = 'boogh/push_token/'
export const NOTIFICATIONS_LIST = 'boogh/in_app_notif/'

export const INVALIDATE_PUSH_TOKEN = 'boogh/push_token'

// BUCK endpoints
export const FINANCIAL_REPORT_URL = `buck/expert/financial-report/`
export const FINANCIAL_PERFORMANCE_REPORT_URL = `buck/expert/performance-report/`

import { ReactNode, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { routePaths } from '../routes/routes'
import { usePushTokenMutation } from '../api/notification/Notification.api'
import { DEVICE_ID, FCM_TOKEN } from '../utils/consts'
import {
  getDeviceIdFromCookie,
  getFcmTokenFromCookie,
  setDeviceIdCookie,
  setFcmTokenCookie,
} from '../utils/cookieManager'
import { userIsLoggedIn } from '../utils/userAuthTool'

type Props = {
  children?: ReactNode
}
const AuthGuard = ({ children }: Props) => {
  const [searchParams] = useSearchParams()
  const pushToken = usePushTokenMutation()

  useEffect(() => {
    const fcmTokenParam = searchParams.get(FCM_TOKEN)
    const deviceIdParam = searchParams.get(DEVICE_ID)

    if (fcmTokenParam && deviceIdParam) {
      setDeviceIdCookie(deviceIdParam)
      setFcmTokenCookie(fcmTokenParam)
    }

    if (getFcmTokenFromCookie() && getDeviceIdFromCookie()) {
      pushToken.mutate({
        token: getFcmTokenFromCookie(),
        device_id: getDeviceIdFromCookie() ?? '',
        app_id: 'P',
      })
    }
  }, [])

  if (!userIsLoggedIn()) {
    return <Navigate to={routePaths.AUTH} replace={true} />
  }
  return <>{children}</>
}
export default AuthGuard

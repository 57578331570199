import { Box } from '@chakra-ui/react'
import Lottie, { Options } from 'react-lottie'
import loadingAnimation from '../assets/lottie/fallback_loading.json'

const FallbackLoading = () => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Box width='100%' bg='white' height='calc(100vh - 64px)' display='flex' justifyContent='center'>
      <Lottie
        style={{
          margin: 'auto',
        }}
        width={300}
        height={300}
        options={defaultOptions}
      />
    </Box>
  )
}
export default FallbackLoading
